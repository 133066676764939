<template>
  <div class="industry-preview">
    <div class="detail-container" v-if="back == 0">
      <div class="back-icon">
        <i class="el-icon-arrow-left" @click="goBack"></i>
      </div>
      <div :class="isFullscreen ? 'full-file-content ql-editor' : 'file-content ql-editor'" v-show="fileType == 1"
				v-html="detailData.detail"></div>
      <div
        class="file-content"
        id="content"
        v-show="fileType != 1"
        @scroll="scrollEvent"
      >
        <pdf
          v-for="item in pageArray"
          :key="item"
          :src="src"
          :page="item"
          v-show="fileType == 2"
        ></pdf>
        <div id="wordView" class="wordView" v-show="fileType == 3" v-html="wordHtml" />
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import MemberModal from "@/components/MemberModal";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import mammoth from "mammoth";
import uni from "../../utils/uni-webview";
export default {
  components: {
    pdf,
    MemberModal
  },
  data() {
    return {
      id: null,
      type: null, // 1 行业动态详情 2 政策规划库详情 3 资料报告库详情
      detailData: {},
      numPages: "", // 总页数
      times: 1,
      yushu: 1,
      pageArray: [],
      currentPage: 1,
      nextShow: false,
      src: "",
      wordHtml: "",
      fileType: "", // 1 富文本 2 pdf 3 word
      back: 0,
      isFullscreen:false
    };
  },
  mounted() {
    this.$session.setLoginToken(this.$route.query.token);
    let type = this.$route.query.type;
    let id = this.$route.query.id;
    let platform = this.$route.query.platform;
    if (type == 1) {
      this.getIndustryDynamicInfo(id, platform);
    } else if (type == 2) {
      this.getPolicyPlanInfo(id, platform);
    } else {
      this.getDatumReportInfo(id, platform);
    }
    this.type = type;
    this.id = id;
    if (this.$route.query.back == 1) {
      this.back = 1;
    } else {
      this.back = 0;
    }
  },
  methods: {
    goBack() {
      uni.navigateBack();
    },
    scrollEvent(e) {
      if (
        Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=
        e.currentTarget.scrollHeight
      ) {
        //容差：20px
        console.log("滚动到底部");
        if (this.currentPage == this.times - 1) {
          this.nextShow = false;
        }
        if (this.currentPage == this.times) {
          // this.nextShow = false;
          return;
        }
        if ((this.currentPage + 1) * 5 <= this.numPages) {
          for (
            let i = this.currentPage * 5 + 1;
            i <= (this.currentPage + 1) * 5;
            i++
          ) {
            this.pageArray.push(i);
          }
        } else {
          for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
            this.pageArray.push(i);
          }
        }
        this.currentPage++;
      }
    },
    // 网页全屏方法
    click() {
      // if (!screenfull.isEnabled) {
      //   this.$message({ message: "你的浏览器不支持全屏", type: "warning" });
      //   return false;
      // }
      // screenfull.toggle();
      if (this.isFullscreen) {
        this.toRefresh();
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".detail-container").style.width = "1200px";
        document.querySelector(".file-content").style.padding = "10px 200px";
      } else {
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".detail-container").style.width = "100vw";
      }
    },
    // 获取行业动态详情
    getIndustryDynamicInfo(id, platform) {
      this.$api.industry
        .getIndustryDynamicInfo({
          industryDynamicId: id,
          platform
        })
        .then(res => {
          let data = res.data;
          if (data.uploadWay == "60471002") {
            if (data.url.toLowerCase().indexOf(".pdf") != -1) {
              // pdf文件
              this.fileType = 2;
              const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.3)",
                fullscreen: false
              });
              this.$api.industry
                .getIndustryDynamicFile({
                  industryId: id,
                  platform
                })
                .then(res => {
                  this.src = this.$utils.getObjectURL(res);
                  let src = pdf.createLoadingTask({
                    url: this.src,
                    CMapReaderFactory
                  });
                  src.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                    loading.close();
                    this.times = Number.parseInt(this.numPages / 5);
                    this.yushu = this.numPages % 5;

                    if (this.yushu > 0) {
                      this.times++;
                      if (this.times == 1) {
                        this.nextShow = false;
                        for (let i = 1; i <= this.yushu; i++) {
                          this.pageArray.push(i);
                        }
                      } else {
                        this.nextShow = true;
                        for (let i = 1; i <= 5; i++) {
                          this.pageArray.push(i);
                        }
                      }
                    } else if (this.yushu == 0) {
                      if (this.times == 1) {
                        this.nextShow = false;
                      } else {
                        this.nextShow = true;
                      }
                      for (let i = 1; i <= 5; i++) {
                        this.pageArray.push(i);
                      }
                    }
                  });
                });
            } else {
              // word文档
              this.fileType = 3;
              this.$api.industry
                .getIndustryDynamicFile({
                  industryId: id,
                  platform
                })
                .then(res => {
                  this.src = this.$utils.getObjectURL(res);
                  this.reviewWord(data.url, this.src);
                });
            }
          }else{
            this.fileType = 1
            this.detailData = data;

          }
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取政策规划详情
    getPolicyPlanInfo(id, platform) {
      this.$api.industry
        .getPolicyPlanInfo({
          policyPlanId: id,
          platform
        })
        .then(res => {
          let data = res.data;
          if (data.uploadWay == "60471002") {
            if (data.url.toLowerCase().indexOf(".pdf") != -1) {
              // pdf文件
              this.fileType = 2;
              const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.3)",
                fullscreen: false
              });
              this.$api.industry
                .getPolicyPlanInfoFile({
                  policyPlanId: id,
                  platform
                })
                .then(res => {
                  this.src = this.$utils.getObjectURL(res);
                  let src = pdf.createLoadingTask({
                    url: this.src,
                    CMapReaderFactory
                  });
                  src.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                    console.log(this.numPages);
                    loading.close();
                    this.times = Number.parseInt(this.numPages / 5);
                    this.yushu = this.numPages % 5;

                    if (this.yushu > 0) {
                      this.times++;
                      if (this.times == 1) {
                        this.nextShow = false;
                        for (let i = 1; i <= this.yushu; i++) {
                          this.pageArray.push(i);
                        }
                      } else {
                        this.nextShow = true;
                        for (let i = 1; i <= 5; i++) {
                          this.pageArray.push(i);
                        }
                      }
                    } else if (this.yushu == 0) {
                      if (this.times == 1) {
                        this.nextShow = false;
                      } else {
                        this.nextShow = true;
                      }
                      for (let i = 1; i <= 5; i++) {
                        this.pageArray.push(i);
                      }
                    }
                    console.log(this.pageArray);
                  });
                });
            } else {
              // word文档
              this.fileType = 3;
              this.$api.industry
                .getPolicyPlanInfoFile({
                  policyPlanId: id,
                  platform
                })
                .then(res => {
                  this.src = this.$utils.getObjectURL(res);
                  this.reviewWord(data.url, this.src);
                });
            }
          }
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取资料报告详情
    getDatumReportInfo(id, platform) {
      this.$api.industry
        .getDatumReportInfo({
          datumReportId: id,
          platform
        })
        .then(res => {
          let data = res.data;
          if (data.uploadWay == "60471002") {
            if (data.url.toLowerCase().indexOf(".pdf") != -1) {
              // pdf文件
              this.fileType = 2;
              const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.3)",
                fullscreen: false
              });
              this.$api.industry
                .getDatumReportInfoFile({
                  datumReportId: id,
                  platform
                })
                .then(res => {
                  this.src = this.$utils.getObjectURL(res);
                  let src = pdf.createLoadingTask({
                    url: this.src,
                    CMapReaderFactory
                  });
                  src.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                    console.log(this.numPages);
                    loading.close();
                    this.times = Number.parseInt(this.numPages / 5);
                    this.yushu = this.numPages % 5;

                    if (this.yushu > 0) {
                      this.times++;
                      if (this.times == 1) {
                        this.nextShow = false;
                        for (let i = 1; i <= this.yushu; i++) {
                          this.pageArray.push(i);
                        }
                      } else {
                        this.nextShow = true;
                        for (let i = 1; i <= 5; i++) {
                          this.pageArray.push(i);
                        }
                      }
                    } else if (this.yushu == 0) {
                      if (this.times == 1) {
                        this.nextShow = false;
                      } else {
                        this.nextShow = true;
                      }
                      for (let i = 1; i <= 5; i++) {
                        this.pageArray.push(i);
                      }
                    }
                    console.log(this.pageArray);
                  });
                });
            } else {
              // word文档
              this.fileType = 3;
              this.$api.industry
                .getDatumReportInfoFile({
                  datumReportId: id,
                  platform
                })
                .then(res => {
                  this.src = this.$utils.getObjectURL(res);
                  this.reviewWord(data.url, this.src);
                });
            }
          }
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 超出继续阅读
    next() {
      console.log(this.currentPage, this.times);
      if (this.currentPage == this.times - 1) {
        this.nextShow = false;
      }
      if (this.currentPage == this.times) {
        // this.nextShow = false;
        return;
      }
      if ((this.currentPage + 1) * 5 <= this.numPages) {
        for (
          let i = this.currentPage * 5 + 1;
          i <= (this.currentPage + 1) * 5;
          i++
        ) {
          this.pageArray.push(i);
        }
      } else {
        for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
          this.pageArray.push(i);
        }
      }
      this.currentPage++;
    },
    // 解析docx地址
    reviewWord(url, src) {
      if (url.toLowerCase().indexOf(".docx") == -1) {
        this.$nextTick(() => {
          this.wordHtml = "<p>暂不支持.doc文件预览！！！</p>";
        });
        return;
      }
      const xhr = new XMLHttpRequest();
      xhr.open("get", src, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = () => {
        if (xhr.status === 200) {
          mammoth
            .convertToHtml({ arrayBuffer: new Uint8Array(xhr.response) })
            .then(resultObject => {
              this.$nextTick(() => {
                this.wordHtml = resultObject.value;
              });
            });
        }
      };
      xhr.send();
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
